import permissions from "../constants/permissions";
import CafeteriaModuleLayout from "@/views/Pages/CafeteriaModule/CafeteriaModuleLayout.vue";

//import middleware
import authorized from "@/middleware/authorized";
import authenticated from "@/middleware/authenticated";

//import pages
const CafeteriaPage = () =>
  import("@/views/Pages/CafeteriaModule/CafeteriaPage.vue");

const ListCafeteriaPage = () =>
  import(
    "@/views/Pages/CafeteriaModule/CafeteriaManagement/ListCafeteriaPage.vue"
  );
const AddCafeteriaPage = () =>
  import(
    "@/views/Pages/CafeteriaModule/CafeteriaManagement/AddCafeteriaPage.vue"
  );
const EditCafeteriaPage = () =>
  import(
    "@/views/Pages/CafeteriaModule/CafeteriaManagement/EditCafeteriaPage.vue"
  );
const ViewCafeteriaPage = () =>
  import(
    "@/views/Pages/CafeteriaModule/CafeteriaManagement/ViewCafeteriaPage.vue"
  );

const ListEstablishmentPage = () =>
  import(
    "@/views/Pages/CafeteriaModule/EstablishmentManagement/ListEstablishmentPage.vue"
  );
const AddEstablishmentPage = () =>
  import(
    "@/views/Pages/CafeteriaModule/EstablishmentManagement/AddEstablishmentPage.vue"
  );
const EditEstablishmentPage = () =>
  import(
    "@/views/Pages/CafeteriaModule/EstablishmentManagement/EditEstablishmentPage.vue"
  );
const ViewEstablishmentPage = () =>
  import(
    "@/views/Pages/CafeteriaModule/EstablishmentManagement/ViewEstablishmentPage.vue"
  );

const ListServiceCenterPage = () =>
  import(
    "@/views/Pages/CafeteriaModule/ServiceCenterManagement/ListServiceCenterPage.vue"
  );
const AddServiceCenterPage = () =>
  import(
    "@/views/Pages/CafeteriaModule/ServiceCenterManagement/AddServiceCenterPage.vue"
  );
const EditServiceCenterPage = () =>
  import(
    "@/views/Pages/CafeteriaModule/ServiceCenterManagement/EditServiceCenterPage.vue"
  );
const ViewServiceCenterPage = () =>
  import(
    "@/views/Pages/CafeteriaModule/ServiceCenterManagement/ViewServiceCenterPage.vue"
  );

const ListCashRegisterPage = () =>
  import(
    "@/views/Pages/CafeteriaModule/CashRegisterManagement/ListCashRegisterPage.vue"
  );
const AddCashRegisterPage = () =>
  import(
    "@/views/Pages/CafeteriaModule/CashRegisterManagement/AddCashRegisterPage.vue"
  );
const EditCashRegisterPage = () =>
  import(
    "@/views/Pages/CafeteriaModule/CashRegisterManagement/EditCashRegisterPage.vue"
  );
const ViewCashRegisterPage = () =>
  import(
    "@/views/Pages/CafeteriaModule/CashRegisterManagement/ViewCashRegisterPage.vue"
  );


  
const ListCashRegisterReadingPage = () =>
import(
  "@/views/Pages/CafeteriaModule/CashRegisterReadingManagement/ListCashRegisterReadingPage.vue"
);
const AddCashRegisterReadingPage = () =>
import(
  "@/views/Pages/CafeteriaModule/CashRegisterReadingManagement/AddCashRegisterReadingPage.vue"
);
const EditCashRegisterReadingPage = () =>
import(
  "@/views/Pages/CafeteriaModule/CashRegisterReadingManagement/EditCashRegisterReadingPage.vue"
);
const ViewCashRegisterReadingPage = () =>
import(
  "@/views/Pages/CafeteriaModule/CashRegisterReadingManagement/ViewCashRegisterReadingPage.vue"
);

let cafeteriasManagementPages = {
  path: "cafeterias",
  component: CafeteriaModuleLayout,
  children: [
    {
      path: "",
      name: "List Cafeterias",
      components: { default: ListCafeteriaPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_CAFETERIAS,
      },
    },
    {
      path: "add",
      name: "Add Cafeteria",
      components: { default: AddCafeteriaPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_CREATE_CAFETERIAS,
      },
    },
    {
      path: "edit/:id",
      name: "Edit Cafeteria",
      components: { default: EditCafeteriaPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_EDIT_CAFETERIAS,
      },
    },
    {
      path: "view/:id",
      name: "View Cafeteria",
      components: { default: ViewCafeteriaPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_CAFETERIAS,
      },
    },
  ],
};

let establishmentsManagementPages = {
  path: "establishments",
  component: CafeteriaModuleLayout,
  children: [
    {
      path: "",
      name: "List Establishments",
      components: { default: ListEstablishmentPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_ESTABLISHMENTS,
      },
    },
    {
      path: "add",
      name: "Add Establishment",
      components: { default: AddEstablishmentPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_CREATE_ESTABLISHMENTS,
      },
    },
    {
      path: "edit/:id",
      name: "Edit Establishment",
      components: { default: EditEstablishmentPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_EDIT_ESTABLISHMENTS,
      },
    },
    {
      path: "view/:id",
      name: "View Establishment",
      components: { default: ViewEstablishmentPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ESTABLISHMENTS,
      },
    },
  ],
};

let serviceCentersManagementPages = {
  path: "service-centers",
  component: CafeteriaModuleLayout,
  children: [
    {
      path: "",
      name: "List ServiceCenters",
      components: { default: ListServiceCenterPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_SERVICECENTERS,
      },
    },
    {
      path: "add",
      name: "Add ServiceCenter",
      components: { default: AddServiceCenterPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_CREATE_SERVICECENTERS,
      },
    },
    {
      path: "edit/:id",
      name: "Edit ServiceCenter",
      components: { default: EditServiceCenterPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_EDIT_SERVICECENTERS,
      },
    },
    {
      path: "view/:id",
      name: "View ServiceCenter",
      components: { default: ViewServiceCenterPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_SERVICECENTERS,
      },
    },
  ],
};
let cashRegistersManagementPages = {
  path: "cash-registers",
  component: CafeteriaModuleLayout,
  children: [
    {
      path: "",
      name: "List CashRegisters",
      components: { default: ListCashRegisterPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_CASH_REGISTERS,
      },
    },
    {
      path: "add",
      name: "Add CashRegister",
      components: { default: AddCashRegisterPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_CREATE_CASH_REGISTERS,
      },
    },
    {
      path: "edit/:id",
      name: "Edit CashRegister",
      components: { default: EditCashRegisterPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_EDIT_CASH_REGISTERS,
      },
    },
    {
      path: "view/:id",
      name: "View CashRegister",
      components: { default: ViewCashRegisterPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_CASH_REGISTERS,
      },
    },
  ],
};
let cashRegisterReadingsManagementPages = {
  path: "cash-register-readings",
  component: CafeteriaModuleLayout,
  children: [
    {
      path: "",
      name: "List CashRegisterReadings",
      components: { default: ListCashRegisterReadingPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_CASH_REGISTER_READINGS,
      },
    },
    {
      path: "add",
      name: "Add CashRegisterReading",
      components: { default: AddCashRegisterReadingPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_CREATE_CASH_REGISTER_READINGS,
      },
    },
    {
      path: "edit/:id",
      name: "Edit CashRegisterReading",
      components: { default: EditCashRegisterReadingPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_EDIT_CASH_REGISTER_READINGS,
      },
    },
    {
      path: "view/:id",
      name: "View CashRegisterReading",
      components: { default: ViewCashRegisterReadingPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_CASH_REGISTER_READINGS,
      },
    },
  ],
};

const cafeteriaModuleRoutes = [
  {
    path: "",
    component: CafeteriaModuleLayout,
    children: [
      {
        path: "",
        name: "Cafeteria Page",
        components: { default: CafeteriaPage },
        meta: { middleware: authenticated },
      },
    ],
  },
  serviceCentersManagementPages,
  establishmentsManagementPages,
  cafeteriasManagementPages,
  cashRegistersManagementPages,
  cashRegisterReadingsManagementPages
];

export default cafeteriaModuleRoutes;
