import qs from "qs";
import axios from "axios";
import Jsona from "jsona";

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

const includes = [
  "sourceWarehouse",
  "destinationWarehouse",
  "items",
  "items.storable",
  "items.storable.unitOfMeasure",
  "items.storable.suppliers",
  "items.storable.categories",
  "items.storable.orderUnitOfMeasureUnit",
  "items.unitOfMeasureUnit",
  "organization",
  "allowedLocations",
  "destinationWarehouse",
  "triggerObject",
  "serviceCenter",
  "establishment",
  "cafeteria",
];

function list(params) {
  const options = {
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { encode: false });
    },
  };

  return axios.get(`${url}/stock-movements`, options).then((response) => {
    return {
      list: jsona.deserialize(response.data),
      meta: response.data.meta,
    };
  });
}

function get(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .get(`${url}/stock-movements/${id}?include=${includes.join(",")}`, options)
    .then((response) => {
      let stockMovement = jsona.deserialize(response.data);
      delete stockMovement.links;
      return stockMovement;
    });
}

function add(stockMovement) {
  const payload = jsona.serialize({
    stuff: stockMovement,
    includeNames: null,
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .post(`${url}/stock-movements`, payload, options)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function update(stockMovement) {
  const payload = jsona.serialize({
    stuff: stockMovement,
    includeNames: [],
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .patch(
      `${url}/stock-movements/${stockMovement.id}?include=${includes.join(
        ","
      )}`,
      payload,
      options
    )
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function destroy(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios.delete(`${url}/stock-movements/${id}`, options);
}

function validate(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .get(
      `${url}/stock-movements/${id}/validate?include=${includes.join(",")}`,
      options
    )
    .then((response) => {
      return response.data;
    });
}

function cancel(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .get(
      `${url}/stock-movements/${id}/cancel?include=${includes.join(",")}`,
      options
    )
    .then((response) => {
      return response.data;
    });
}

function print(params) {
  const options = {
    headers: {},
    responseType: "blob",
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { encode: false });
    },
  };

  return axios.get(`${url}/stock-movements/${params.id}/print`, options);
}

export default {
  list,
  get,
  add,
  update,
  destroy,
  validate,
  cancel,
  print,
};
