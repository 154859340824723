export default {
  EVENTS_LIST: "Liste des évènements",
  ADD_EVENT: "Ajouter un évènement",
  EVENT_ADDED: "Évènement ajouté",
  EDIT_EVENT: "Modifier un évènement",
  EVENT_UPDATED: "Évènement mis à jour",
  DELETE_THIS_EVENT: "Supprimer ce évènement ?",
  EVENT_DELETED: "Évènement supprimé",
  STARTS_AT: "Début",
  ENDS_AT: "Fin",
  TITLE: "Titre",
  EVENT_CATEGORY_PEDAGO: "Pédagogie",
  EVENT_CATEGORY_THEMATIC_MEAL: "Repas Thématique",
  EVENT_CATEGORY_TASTING: "Dégustation",
  EVENT_CATEGORY_PROMOTION: "Promotion",
  EVENT_CATEGORY_EVENT: "Événement",
  EVENT_CATEGORY_INTERNAL_JOURNAL: "Journal Interne",
  EVENT_CATEGORY_CLIENT_JOURNAL: "Journal Client",
  EVENT_CATEGORY_SURVEY: "Sondage",
  EVENT_CATEGORY_MONTHLY_INSPIRATION: "Inspiration du Mois",
  EVENT_CATEGORY_OPEN_HOUSE: "Porte-Ouverte",
  SHOW_MY_EVENTS: "Voir les évènements qui me concernent",
  SHOW_ALL: "Voir tous les évènements",
  EVENT_CATEGORY_EVENT: "Événement",
  EVENT_CATEGORY_LEAVE: "Congé",
  EVENT_CATEGORY_SPECIAL_THEME_MENU: "Menu Spécial et Thématique",
  EVENT_CATEGORY_TRAINING_MEETING: "Formation et Réunion",
  EVENT_CATEGORY_DEADLINE_DELIVERABLE: "Échéance et Livrable",
  EVENT_CATEGORY_OTHER: "Autres / Divers",
  GRID_MONTH: "Mois",
  GRID_WEEK: "Semaine",
  LIST_MONTH: "Liste Mois",
  LIST_WEEK: "Liste Semaine",
};
