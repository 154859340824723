import Vue from "vue";
import Vuex from "vuex";

import appConfig from "./modules/app-config-module";
import auth from "./modules/auth";
import files from "./modules/files-module";
import folders from "./modules/folders-module";
// import alerts from "./modules/alerts-module";
import reset from "./modules/reset";
import profile from "./modules/profile-module";
import users from "./modules/users-module";
import roles from "./modules/roles-module";
import permissions from "./modules/permissions-module";
import organizations from "./modules/organizations-module";
import resellers from "./modules/resellers-module";
import resellerProducts from "./modules/reseller-products-module";
import resellerServices from "./modules/reseller-services-module";
import resellerInvoices from "./modules/reseller-invoices-module";
import resellerInvoiceItems from "./modules/reseller-invoice-items-module";
import resellerPayments from "./modules/reseller-payments-module";
import packages from "./modules/packages-module";
import customers from "./modules/customers-module";
import logs from "./modules/logs-module";
import subscriptions from "./modules/subscriptions-module";
import payments from "./modules/payments-module";
import contacts from "./modules/contacts-module";
import suppliers from "./modules/suppliers-module";
import tags from "./modules/tags-module";
import locations from "./modules/locations-module";
import notifications from "./modules/notifications-module";
import notificationSubscriptions from "./modules/notification-subscriptions-module";
import cafeterias from "./modules/cafeterias-module";
import establishments from "./modules/establishments-module";
import serviceCenters from "./modules/service-centers-module";
import products from "./modules/products-module";
import warehouses from "./modules/warehouses-module";
import warehouseProducts from "./modules/warehouse-products-module";
import purchasesOrders from "./modules/purchases-orders-module";
import purchasesOrderItems from "./modules/purchases-order-items-module";
import purchasesInvoices from "./modules/purchases-invoices-module";
import purchasesInvoiceItems from "./modules/purchases-invoice-items-module";
import purchasesDeliveries from "./modules/purchases-deliveries-module";
import purchasesDeliveryItems from "./modules/purchases-delivery-items-module";
import purchasesPayments from "./modules/purchases-payments-module";
import salesOrders from "./modules/sales-orders-module";
import salesOrderItems from "./modules/sales-order-items-module";
import salesInvoices from "./modules/sales-invoices-module";
import salesInvoiceItems from "./modules/sales-invoice-items-module";
import salesDeliveries from "./modules/sales-deliveries-module";
import salesDeliveryItems from "./modules/sales-delivery-items-module";
import salesPayments from "./modules/sales-payments-module";
import supplierProducts from "./modules/supplier-products-module";
import stockMovements from "./modules/stock-movements-module";
import stockMovementItems from "./modules/stock-movement-items-module";
import imports from "@/store/modules/import-module";
import supplyRules from "./modules/supply-rules-module";
import categories from "./modules/categories-module";
import taxes from "./modules/taxes-module";
import taxGroups from "./modules/tax-groups-module";
import options from "./modules/options-module";
import organizationOptions from "./modules/organization-options";
import unitOfMeasures from "./modules/unit-of-measures-module";
import unitOfMeasureUnits from "./modules/unit-of-measure-units-module";
import paymentTerms from "./modules/payment-terms-module";
import events from "./modules/events-module";
import cashRegisters from "./modules/cash-registers-module";
import cashRegisterReadings from "./modules/cash-register-readings-module";
import cashRegisterReadingItems from "./modules/cash-register-reading-items-module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    appConfig,
    auth,
    files,
    folders,
    // alerts,
    reset,
    profile,
    users,
    roles,
    permissions,
    organizations,
    resellers,
    resellerServices,
    resellerProducts,
    resellerInvoices,
    resellerInvoiceItems,
    resellerPayments,
    customers,
    logs,
    packages,
    subscriptions,
    payments,
    contacts,
    suppliers,
    tags,
    locations,
    notifications,
    notificationSubscriptions,
    cafeterias,
    establishments,
    serviceCenters,
    products,
    warehouses,
    warehouseProducts,
    purchasesOrders,
    purchasesOrderItems,
    purchasesInvoices,
    purchasesInvoiceItems,
    purchasesDeliveries,
    purchasesDeliveryItems,
    purchasesPayments,
    salesOrders,
    salesOrderItems,
    salesInvoices,
    salesInvoiceItems,
    salesDeliveries,
    salesDeliveryItems,
    salesPayments,
    supplierProducts,
    stockMovements,
    stockMovementItems,
    imports,
    supplyRules,
    categories,
    taxes,
    taxGroups,
    options,
    organizationOptions,
    unitOfMeasures,
    unitOfMeasureUnits,
    paymentTerms,
    events,
    cashRegisters,
    cashRegisterReadings,
    cashRegisterReadingItems
  },
});
