import store from "../store";
import moment from "moment";
import 'moment-timezone';

const timeZoneDateFormat = {
  install: (Vue) => {
    Vue.prototype.$timeZoneDateFormat = (value, format = "LLL") => {
      const me = store.getters["profile/me"];
      const momentDate = moment.tz(value, "UTC").tz(moment.tz.guess());
      return momentDate.locale(me?.locale ?? "fr").format(format);
    };
    Vue.prototype.$getUtcDateTimeString = (value) => {
      return moment.tz(value, moment.tz.guess()).tz("UTC").format();
    };
  },
};

export default timeZoneDateFormat;
